
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



















.blog-archive-author {
  ::v-deep {
    .section-hero__content {
      width: 75%;
    }

    .blog-author {
      @include mq($from: m, $until: l) {
        flex-direction: row;
        align-items: center;
      }

      @include mq($until: xs) {
        flex-direction: column;
        align-items: flex-start;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    .blog-author__picture__container {
      width: 180px;
      height: 180px;

      @include mq($until: s) {
        width: 70px;
        height: 70px;
      }
    }

    .blog-author__profile {
      z-index: 2;
    }

    .blog-author__profile__name {
      font-size: 5rem;
      font-family: $ff-alt;
      line-height: 1.3em;

      @include mq($until: s) {
        font-size: 3.5rem;
      }
    }

    .blog-author__profile__short-description {
      font-size: 3rem;

      @include mq($until: s) {
        font-size: 2rem;
      }
    }
  }
}
